import logo from './logo.svg';
import style from "./App.module.scss";

import radiodays from "./assets/radiodays.jpeg";
import cover from "./assets/cover.png";
import { FaPhone } from "react-icons/fa";


function App() {
  return (
    <div className="App">
      <header className={style.navbar}>
        <div className={style.headerInner}>
          <img className={style.logo} src={logo} alt="Appidea.pl" />
          <a href="#contact">
            <FaPhone size={28} />
          </a>
        </div>
      </header>


      <div className={style.hero}>
        <div className={style.heroContent}>
          <div className={style.heroContentTexts}>
            <h2>The world is built on ideas.</h2>
            <h1>We believe in Your</h1>
            <img className={style.bigLogo} src={logo} alt="appidea" />
          </div>
        </div>
      </div>

      <div className={style.page}>
        <section className={style.pageInner}>

          <div className={style.heading}>
            <span>Modern world needs</span>
            <h2>modern solutions.</h2>
          </div>

          <img src={cover} className={style.cover} alt="Illustration: Cover indicator" />
          <p className={style.companyBio}>
            Appidea creates web experiences since 2008.<br />
            <b>
              We provide to our Clients the crafted solutions in web and mobile apps area.
            </b><br />
            Our solutons are adaptable to any device and platform. And to the costantly changing world, that brings new challenges and technologies every day.

          </p>
        </section>

        <section className={style.pageInner}>

          <div className={style.heading}>
            <span>Introduce One Playback Experience</span>
            <h2>using Amos.</h2>
          </div>

          <p className={style.companyBio}>
            <b>Amos.</b> is our newest product. With Amos, it is possible to introduce One Playback Experience for Your radio or audio platform.
            Your radio library, players and news feeds can be presented the same way on Mobile, Web, TVs and even{' '}
            <b>in the car infotainment systems, using our ready-to-go Amos modules.</b> Everything with Your branding, to
            let You decide about the content.
            Learn more at <a href="https://useAmos.com">useAmos.com</a>
          </p>

          <a href="https://useAmos.com/">
            <img src="https://useamos.com/static/media/logo.a2a0358a7ae96eb4e8a0.png" style={{maxWidth: '300px'}} alt="Amos. Logo" />
          </a>
        </section>

        <section id="contact" className={style.contact}>
          <div className={style.pageInner + ' ' + style.bottom}>
            <section className={style.contactForm}>
              <h2>
                Hey! Let’s stay in touch.<br />
                And go through the ideas.
              </h2>

              <p style={{marginBottom: '30px'}}>
                Contact us:
              </p>

              <div className={style.contactRow}>
                <div className={style.contactCol} style={{marginBottom: '10px'}}>
                  <a href="mailto:kontakt@appidea.pl"><b>kontakt@appidea.pl</b></a>
                </div>
                <div className={style.contactCol}>
                  <div style={{marginBottom: '10px'}}>
                    Sales:
                  </div>

                  <div>
                    <a href="tel:+48669055170">
                      Poland: <b>+48 669 055 170</b>
                    </a>
                  </div>

                  <div>
                    <a href="tel:+420775843070">
                      Czech Republic: <b>+420 775 843 070</b>
                    </a>
                  </div>
                </div>
              </div>

            </section>
          </div>

          <div className={style.copy}>
            <div className={style.copyCol}>
              <b>© 2023 Appidea.pl</b>
              <br />
              A Software Development company<br />
              Warsaw, Poland<br />
              NIP: 956 228 39 82
            </div>
            <div className={style.copyCol}>
              Appidea - official sponsor<br />
              <b>of Radiodays 2023</b><br />
              in Prague, Czech Republic<br />
              <a href="https://radiodayseurope.com">RadiodaysEurope.com</a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
